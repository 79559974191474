$brand-primary:             #2d7ad9; // actually a lighter shade of our logo color, #1d5caa.
$brand-primary-alt:         #1d5caa;
$brand-primary-dark:        #415169;
$brand-primary-darkest:     #2D4058;
$yellow:                    #DE951F;
$orange:                    #f3934a;
$cyan:                      #4af3d2;
$light-purple:              #BA85FF;

.layout {
  min-height: 100%;
  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -70px;

  &-navigation {
    background-color: $brand-primary;
    @media (min-width: 600px) {
      &.ant-menu-horizontal > .ant-menu-item.pull-right {
        float: right;
      }
    }
    @media (max-width: 600px) {
     & .link-text {
        display: none;
      }
    }
    &.ant-menu-horizontal > .ant-menu-item-selected > a,
    &.ant-menu-horizontal > .ant-menu-item > a {
      color: #fff;
    }
  }

  &-header {
    background-color: $brand-primary;
    .agolo-logo {
      background-image: url(/images/agolo-logo.svg);
      background-size: contain;
      margin: 25px 0;
      background-repeat: no-repeat;
      float: left;
    }
    .ap-logo {
      background-image: url(/images/ap-logo.png);
      background-size: contain;
      margin: 20px 0;
      background-repeat: no-repeat;
      float: right;
    }
    .liontree-logo {
      background-image: url(/images/liontree-logo.jpg);
      background-size: contain;
      margin: 10px 0;
      background-repeat: no-repeat;
      float: right;
    }
    .agolo-logo, .ap-logo, .liontree-logo {
      width: 70px;
      height: 31px;
      text-align: center;
      img {
        height: 1.5rem
      }
    }
    .liontree-logo {
      height: 45px;
    }
    @media (max-width: 325px) {
      padding: 0 25px;
      .agolo-logo, .ap-logo, .liontree-logo {
        width: 70px;
      }
    }
  }

  &-content {
    padding: '0 2rem';
    background: #fff;
    padding: 24px;
    min-height: calc(100vh - 140px);
    .ant-form-item-label {
      text-align: left;
    }
  }

  &-footer {
    height: 70px;
  }
}


